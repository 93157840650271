<template>
  <div>这里是首页</div>
</template>

<script>
import _menu from '@/components/_menu'

export default {
  created () {
    _menu.gotoDesktop(this, true);
  }
}
</script>